.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    background-color: rgba(0, 0, 0, 0.4) !important;
  }


  .form-control{
    border:1px solid grey;
    padding:10px;
    width:100%;
    background-color: white;
    border-radius: 5px;
  }

  select, select option{
    font-size:18px;
  }