@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@500&family=Poppins:wght@300;400;500&family=Sora:wght@100;200;300;400;500;600&display=swap');

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans");
}

*:focus{
  outline: none !important;
}

// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// resposive-section break point
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@mixin breakpoint($point) {
  @if $point==laptop {
      @media screen and (max-width: 1200px) {
          @content ;
      }
  }
  @if $point==tablet_landscap {
      @media screen and(max-width: 991px) {
          @content ;
      }
  }
  @if $point==tablet_portrait {
      @media screen and(max-width: 768px) {
          @content ;
      }
  }
  @if $point==mobile {
      @media screen and(max-width: 598px) {
          @content ;
      }
  }
}

$Description: 'Sora' !important;
$Heading: "Sora" !important;

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
link,
a {
  font-family: "Sora";
  margin: 0;
  padding: 0;
}

div,
p,
Label {
  font-family: "Sora";
}



.SideBarLogo {
  text-align: center;
  position: absolute;
  width: 100%;
  margin: auto;
  color: white;
  font-weight: bold;
}

#SideBar .MuiPaper-root.MuiDrawer-paper {
  background-color: #ffffff!important;
  min-width: 73px;
}

.sideBarIcon {
  color: #212529;
  padding: 3px;
  // background-color: #ff674d;
  border-radius: 3px;
  width: 20px;
}

.breadcrum {
  color: white;
  font-weight: bold;
  font-size: 20;
}
.nav-btn {
  padding: 0 !important;
  margin: 13px !important;
  width: auto !important;

  a {
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    color:  white !important;
    outline: none;
    text-decoration: none;
  }
  a:active{
    border: 1px solid #707070;
  }
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.d-flex {
  display: flex !important;
}
.iconContainer {
  color: black;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  transition: all 200ms linear;
  .MuiListItemIcon-root {
    min-width: unset;
  }
  .MuiListItemText-root {
    margin: 0 0 0 15px;
  }
  &:hover {
    color: unset;
  }
}




.dashboardMainDivContainer {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #F6F6F6;
  min-height: 100vh;
  
  
  .card {
    background-color: white;
    padding: 2rem 1rem 2rem 1rem;
    border: none;
    box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);

    .titles{
      font-size: 26px;
      color: #064789;
      text-transform: uppercase;
      text-align: left;
      font-weight: 600;
      margin-top: 0;      
    }
  }
}
.BtnLinkDist{
  text-align: right;
  text-decoration: none;
  margin-left: auto;
}

.BrandAddBtn {
  background-color: rgb(0, 172, 193) !important;
  color: rgb(255, 255, 255) !important;
  width: fit-content;
  text-align: end;
  // margin-left: 1050px !important;
  border-radius: 15px !important;
  text-transform: capitalize !important;
  font-size: 17px !important;
  background: #707070;
}

#DashboardTable {
  .Edit {
    background-color: #00acc1;
    border-radius: 20px;
    color: white;
    padding: 4px;
    margin-right: 10px;
    outline: none;
  }
  .Delete {
    background-color: #ff9800;
    border-radius: 20px;
    color: white;
    padding: 4px;
    margin-right: 10px;
    outline: none;
  }
  .Disable {
    background-color: #f44336;
    border-radius: 20px;
    color: white;
    padding: 4px;
    margin-right: 10px;
    outline: none;
  }
}

.grayBackgroundBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100% !important;
  background-color: #efefef;
  min-height: 100vh;
  padding: 3rem 1rem;
  .card {
    width: 100%;
    background-color: white;
    padding: 3rem 1rem 2rem 1rem;
    border-radius: 30px;
    border: none;
    .title{
      font-size: 26px;
      color: #064789;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
    }
    .inputField{
      width: 80%;
      margin-top: 2rem;
      input{
        margin-top: 1rem;
      }
      @include breakpoint(mobile){
        width: 100%;
        margin-top: 1rem;
      }
    }
    .inputFieldContainer{
      // text-align: center;
      label{
        font-size: 18px;
      }
    }
    .formSubmit{
      border-radius: 5px;
      text-transform: capitalize;
      padding: 6px 50px;
      margin-top: 30px;
      font-size: 18px;
      @include breakpoint(mobile){
        padding:6px 16px;
      }
    }
  }
}

.signOutBtn{
  font-size:20px;
  color:white;
  position:absolute;
  margin-top:40px;
  cursor: pointer;
}
.m-3 {
  margin-top: 3rem !important;
  margin: 0px 15px;
}


.makeStyles-root-12 {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.makeStyles-root-12::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}



.c-dashboardInfo {
  margin-bottom: 15px;
  width:18%;
  margin-left: 1%;
}
.c-dashboardInfo .wrap {
  background: #ffffff;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 40px 25px 20px;
  height: 100%;
}
.c-dashboardInfo__title,
.c-dashboardInfo__subInfo {
  color: #6c6c6c;
  font-size: 1.18em;
}
.c-dashboardInfo span {
  display: block;
}
.c-dashboardInfo__count {
  font-weight: 600;
  font-size: 2.5em;
  line-height: 64px;
  color: #323c43;
}
.c-dashboardInfo .wrap:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  content: "";
}

.c-dashboardInfo:nth-child(1) .wrap:after {
  background: linear-gradient(82.59deg, #00c48c 0%, #00a173 100%);
}
.c-dashboardInfo:nth-child(2) .wrap:after {
  background: linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%);
}
.c-dashboardInfo:nth-child(3) .wrap:after {
  background: linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%);
}
.c-dashboardInfo:nth-child(4) .wrap:after {
  background: linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%);
}
.c-dashboardInfo:nth-child(5) .wrap:after {
  background: linear-gradient(81.67deg, #66ff44 0%, #1f5dc5 100%);
}
.c-dashboardInfo__title svg {
  color: #d7d7d7;
  margin-left: 5px;
}
.MuiSvgIcon-root-19 {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 24px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  flex-shrink: 0;
}


.custom-classname{

  width: 20%;
  height: 50px; 
  margin-right: 1%;
  padding-top: 0px;
  padding-bottom: 10px;
  background-color: #4169E1;
  float: left;
  align-self: flex-start;
  margin-bottom:100px;
  border-radius: 10px;
}

.export-to-tally-button{
  padding-top:10px  !important;
  width:300px;
  font-size: 13px;
}