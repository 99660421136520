.form-containerr{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(https://thebigcluster.com/wp-content/uploads/2021/07/Login.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin: auto;
  min-height: 100vh;
   min-width: 100vw;
}
.form-ddiv{
  // background-color: rgb(163, 81, 163);
  padding: 30px;
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  width: 320px;
  min-height: 300px;
  
  background: rgba( 139, 87, 42, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.form-ddiv h2{
  color: white;
  font-size: 25 px;
  margin-top: -4px !important;
}
.login-email{
  text-align: initial;
  color: white;
  width: 100%;
  font-size: 14px;
  margin-left: 14px;
}
.form-ddiv input{
  margin: 5px;
  margin-left: -15px;
  padding-right: 10px;
  margin-bottom: 15px;
}
.login-passowrd{
  text-align: initial;
  color: white;
  width: 100%;
  font-size: 14px;
  margin-left: 14px;
}


.btn-btn-log{
  
  position: relative;
  background-color: rgb(99, 62, 6);
  padding: 4px 50px;
  text-align: center;
  margin: 25px 3px;
  color: rgb(255, 255, 255);  
  border-radius: 10px;
  margin-right: 14px;
  border-color: rgb(99, 62, 6);
  width: 180px;
  height: 40px;
  font-size: 50;
  font-weight: bold;
}

